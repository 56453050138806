<template>
  <div class="product-card">
    <router-link
      :to="{
        name: 'product',
        params: paramsProductRoute,
        query: queryParams
      }"
    >
      <div v-if="isProductSoldOut" class="product-card__sold-out-badge product-badge">
        Agotado
      </div>

      <div v-else-if="productDiscount" class="product-card__discount-badge product-badge">
        Descuento {{ productDiscount }}%
      </div>

      <div class="product-card__image">
        <img v-if="product.mainImage" :src="product.mainImage" />
        <img v-else class="image-placeholder" src="@/assets/icon-item-default.svg" />
      </div>

      <h5 class="product-card__name">
        {{ product.name }}
      </h5>

      <div class="product-card__price">
        <strong>
          {{ $currencyFormatter(product.finalPrice) }}
        </strong>

        <span class="product-card__price__before-discount" v-if="showFinalPriceBeforeDiscounts">
          {{ $currencyFormatter(product.finalPriceBeforeDiscounts) }}
        </span>

        <div v-if="product.hasNoIvaDays" class="product-card__no-iva-badge">Sin IVA</div>
      </div>
    </router-link>

    <button
      class="product-card__button"
      :class="{ 'is-loading': isAdding }"
      :disabled="isAdding || isProductSoldOut"
      @click="checkInventory"
    >
      <Loading v-if="isAdding" :loading="isAdding" size="small" />
      <span v-else>
        Agregar al carrito
      </span>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import urlUtil from '@/utils/url'
import store from '@/store'
import Loading from '@/components/shared/Loading'

export default {
  name: 'ProductCard',
  components: {
    Loading
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isAdding: false
    }
  },
  computed: {
    ...mapGetters('config', ['mode']),
    paramsProductRoute() {
      const { name, id, category } = this.product

      return {
        product: urlUtil.formatSeoUrl(name) + '-' + id,
        category: category ? `${urlUtil.formatSeoUrl(category.name)}-${category.id}` : 'all',
        origin: this.product
      }
    },
    productDiscount() {
      return this.product.discounts?.discountPercentage
    },
    showFinalPriceBeforeDiscounts() {
      return this.product.finalPriceBeforeDiscounts !== this.product.finalPrice
    },
    isProductSoldOut() {
      const { sellWithoutStock, quantity } = this.product?.inventorySettings || {}

      if (sellWithoutStock || !this.product.inventory) {
        return false
      }

      return quantity <= 0
    },
    queryParams() {
      const query = {}
      if (this.mode) query.mode = this.mode
      return query
    }
  },
  methods: {
    ...mapActions('cart', ['addProductCart']),
    ...mapActions('products', ['getProductById', 'getProducts', 'getFeaturedProducts']),
    async checkInventory() {
      this.isAdding = true

      const { inventory, inventorySettings = {} } = await this.getInventory()

      const shouldCheckForStock = inventory && !inventorySettings?.sellWithoutStock
      const hasNoStock = inventorySettings?.quantity <= 0
      const shouldShowNoStockError = shouldCheckForStock && hasNoStock

      if (shouldShowNoStockError) {
        store.dispatch('notification/showDangerNotification', {
          text: 'No contamos con la cantidad suficiente para el producto solicitado'
        })

        window.scrollTo({ top: 0, behavior: 'smooth' })

        this.isAdding = false

        store.set('products/products', null)
        store.set('products/searchPage', 0)
        this.getProducts()
        this.getFeaturedProducts()
      } else {
        this.addToCart()
      }
    },
    async getInventory() {
      try {
        const product = await this.getProductById({
          idProduct: this.product.id
        })

        return {
          inventory: product.inventory,
          inventorySettings: product.inventorySettings
        }
      } catch (e) {
        store.dispatch('notification/showDangerNotification', {
          text: 'Error creando tu pedido, puedes intentar de nuevo en unos minutos.'
        })
        this.isAdding = false
        return {}
      }
    },
    async addToCart() {
      if (this.product.type === 'variantParent') {
        store.set('modal/addProductWhithVariantsModal@product', this.product)
        store.set('modal/addProductWhithVariantsModal@isOpen', true)
      } else {
        this.addProductCart({ product: this.product, quantity: 1 })
      }
      this.isAdding = false
    }
  }
}
</script>

<style lang="scss">
.product-card {
  background: #ffffff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 700px) {
    padding: 8px;
  }

  &__image {
    height: 272px;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 700px) {
      height: 170px;
    }

    img:not(.image-placeholder) {
      // height: 100%;
      // width: 100%;
      // object-fit: cover;

      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }

    .image-placeholder {
      height: 90px;
    }
  }

  &__name {
    font-weight: 400;
    font-size: 14px;
    color: #334155;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media (max-width: 700px) {
      font-size: 12px;
    }
  }

  &__price {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    strong {
      color: #0f172a;
      font-size: 18px;

      @media (max-width: 700px) {
        font-size: 12px;
      }
    }

    &__before-discount {
      font-weight: 600;
      font-size: 12px;
      text-decoration: line-through;
      color: #cbd5e1;
      margin-left: 8px;
    }
  }

  &__button {
    border: 1px solid #e2e8f0;
    font-size: 14px;
    color: #94a3b8;
    padding: 10px;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease-out;
    margin-top: auto;
    height: 41px;
  }

  &__button:hover {
    background: #1e293b;
    color: #ffffff;
  }

  &__button:disabled {
    background: #f8fafc;
    color: #cbd5e1;
    pointer-events: none;
    cursor: default;
  }

  .product-badge {
    font-size: 12px;
    position: absolute;
    padding: 4px 8px;
    left: 24px;
    top: 24px;
    font-weight: 500;
    border-radius: 4px;

    @media (max-width: 700px) {
      left: 12px;
      top: 12px;
    }
  }

  &__sold-out-badge {
    color: #b45309;
    background: #fef3c7;
  }

  &__discount-badge {
    color: #be123c;
    background: #ffe4e6;
  }

  &__no-iva-badge {
    font-size: 12px;
    padding: 4px 8px;
    font-weight: 500;
    border-radius: 4px;
    color: #15803d;
    background: #dcfce7;
    margin-left: auto;
    margin-right: 30px;

    @media (max-width: 700px) {
      margin-right: 0;
      position: absolute;
      right: 10px;
      top: 140px;
    }
  }
}
</style>
``
