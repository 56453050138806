const variantProductUtil = {}

const itemWithStock = item => {
  const { sellWithoutStock, quantity } = item.inventorySettings || {}
  if (!sellWithoutStock && quantity <= 0) return false
  return true
}

variantProductUtil.searchVariant = (product, variantsSelected) => {
  const response = {
    status: 'SUCCESS', // ERROR || SUCCESS
    variant: null,
    errorMsg: null
  }

  if (!variantsSelected.length) {
    response.status = 'ERROR'
    response.errorMsg = 'Selecciona las variantes del producto'
    return response
  }

  for (const variant of product.variantAttributes) {
    const selectedVariant = variantsSelected.find(selectedVariant => selectedVariant?.idVariant === variant.id)
    if (!selectedVariant) {
      response.status = 'ERROR'
      response.errorMsg = `Selecciona la variante ${variant.name}`
      return response
    }
  }

  for (const item of product.itemVariants) {
    let attributes = 0

    for (const selectedVariant of variantsSelected) {
      const variant = item.variantAttributes.find(({ id }) => id === selectedVariant.idVariant)
      if (variant?.options[0].id === selectedVariant.idOption) attributes++
    }

    if (attributes === product.variantAttributes.length) {
      if (!itemWithStock(item)) {
        response.status = 'ERROR'
        response.errorMsg = 'Producto agotado'
      }

      response.variant = item
      response.variant.idVariantParent = product.id
      return response
    }
  }
}

export default variantProductUtil
